/*************************
    Keycloak Override
**************************/
#kc-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

#kc-content {
    display: flex;
    flex-grow: 1;
}

#kc-oauth .inputGroup {
    display: flex;
    justify-content: flex-end;
}

#kc-oauth h3 {
    margin: 1.5rem 0 1rem 0;
}

#kc-oauth {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    font-family: BoschSansLight;
    font-size: 14px;
    font-weight: 200;
    line-height: 1.5;
    letter-spacing: normal;
}

#kc-oauth .consent {
    margin-bottom: 1rem;
}


/*************************
    Custom Styling
**************************/
.image-banner {
    height: 144px;
}

.consent-screen-text {
    margin-bottom: 10px;
}

.consent-primary-button {
    margin-left: 0.5em;
}

.applicationContent h4 {
    font-family: BoschSansLight;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    padding: 0;
}

.applicationBlock {
    display: flex;
    flex-direction: column;
}

.applicationBlock p {
    margin: 0;
}

.applicationHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
}

.applicationHeader .rbicon {
    margin: 0 10px 0 10px;
}

.applicationHeader > .acceptBlock {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 16px;
}

.applicationHeader > .acceptBlock p {
    pointer-events: none;
    margin-bottom: 0;
    margin-right: 1rem;
    color: #bfc0c2;
}

.applicationHeader h6 {
    margin: 13px 0 13px;
}

.collapsible {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #ffffff;
}

.expandable {
    display: flex;
    padding-left: 1.5rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.app-icon {
    font-size: 24px;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .expandable {
        flex-direction: column;
        align-items: flex-start;
    }
}

.indicator {
    transition: all 0.2s;
}

.indicator.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}
